*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

@font-face {
  font-family: "Fredoka";
  src: url("../public/fonts/Fredoka-VariableFont_wdth\,wght.ttf")
    format("truetype");
  font-weight: 100 900; /* Defines the range of font weights */
  font-stretch: 50% 100%; /* Optional: defines the range of width (wdth) */
  font-style: normal;
}

@font-face {
  font-family: "Quicksand";
  src: url("../public/fonts/Quicksand-VariableFont_wght.ttf") format("truetype");
  font-weight: 100 900; /* Defines the range of font weights */
  font-stretch: 50% 100%; /* Optional: defines the range of width (wdth) */
  font-style: normal;
}
